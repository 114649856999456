import { twJoin } from '@troon/tailwind-preset/merge';
import type { JSX } from 'solid-js';

export function Content(props: JSX.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			{...props}
			class={twJoin('flex flex-col gap-8 overflow-x-hidden px-4 py-8 lg:p-12 2xl:px-24 2xl:py-16', props.class)}
		/>
	);
}
